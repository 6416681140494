import React from "react";
import { handleCallClick } from "../../Utils/SkypeCall";
function Header() {
 
  return (
    <div className="bg-[#1a69b4]">
      <div className="w-full    h-[150px]  flex  sm:justify-between   items-center p-10 container mx-auto justify-center flex-col sm:flex-row   ">
        <div className="h1 text-lg md:text-2xl lg:text-2xl font-bold text-white">
          <a href="#"> Cable Internet</a>
        </div>
        <button onClick={handleCallClick} className="  hover:bg-white hover:text-[#f2ae1b] hover:border-2  hover:border-[#f2ae1b] hover:scale-y-125 btn bg-[#f2ae1b] px-4 py-2 rounded-md">
          +1 (830) 256-0380
        </button>
      </div>
    </div>
  );
}

export default Header;

import React from 'react'
import AnimatedItem from '../Component/AnimatedItem'

function Map() {
  return (
    <AnimatedItem>
    <div className='pt-16'>
        <h1 className='text-center lg:text-4xl text-2xl font-bold pb-6'>Find The Best Offers Available in your area</h1>
        <img src='/assets/map4.svg' className='text-center w-[85%] sm:w-[70%] flex justify-center mx-auto'/>
    </div></AnimatedItem>
  )
}

export default Map
import React from "react";

const Internet = () => {
  return (
    <>
      {" "}
      <div className="md:h-full w-full md:px-16 custom-flex container mx-auto text-center py-[50px]">
        <div className="w-[86%] md:w-3/4">
          <h2 className=" text-center sm:text-left text-xl md:text-3xl lg:text-4xl xl:text-5xl  md:pt-2 font-semibold flex justify-center flex-col sm:flex-row">
            <span className="text-[#1a69b4] text-center mr-2">
              Your Connection to
            </span>
            <span className="text-black underline relative before:content-[''] before:right-6 before:left-6 before:top-0 before:bottom-0 before:absolute before:bg-[#F2AE1B] before:-skew-y-12 before:-z-10">
              Remote Locations
            </span>
          </h2>

          <h3 className="text-black text-lg md:text-2xl py-2 md:leading-9 font-semibold">
            {" "}
            Did you know we are the largest provider of rural internet in the
            United States?
          </h3>
          <div className="text-black text-md md:text-xl md:py-2">
            <p>
              We believe fast, reliable, and affordable internet should be
              accessible to everyone, regardless of location. Whether through
              wireless home internet or satellite, we offer solutions that meet
              diverse needs. If our services aren't available in your area, we
              will assist in connecting you with a suitable local provider
            </p>
          </div>
        </div>
      </div>
      ;
    </>
  );
};

export default Internet;

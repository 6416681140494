import React from "react";

const Info = () => {
  return (
    <div className=" mx-auto   bg-cover bg-no-repeat rounded   h-auto   info-bg">
      <div className=" container  w-[85%] sm:w-[65%] py-[20px] mx-auto text-white flex flex-col gap-[30px]  font-normal  	">
        <h2 className="  text-xl md:text-5xl  ">
          Experience Unparalleled Internet Services
        </h2>
        <h4 className=" text-md md:text-3xl font-semibold">
          Fed up with bait-and-switch offers from providers? Slow speeds, hidden
          fees, unreliable service, and those sneaky price hikes after a year?
          We’ve been there too
        </h4>
        <div className="text-lg md:text-2xl flex flex-col gap-[20px] ">
          <p>
            We're more than just a provider—we're the internet partner you can
            trust. Plus, we're more widely available than any other ISP across
            the continental U.S.
          </p>
          <p>
            Avoid the frustrations and gimmicks from the usual suspects like
            Xfinity, Cox, Spectrum, Bright House, Optimum, and others. Say
            goodbye to chasing flashy offers and being left on hold indefinitely
          </p>
        </div>
      </div>
    </div>
  );
};

export default Info;

import React from "react";
import Info from "./Info";

const Experience = () => {
  return (
    <>
      <div className="exp-main ">
        <div className=" mx-auto container flex flex-col gap-[30px]">
          <div className="p-6 bg-white rounded-md overflow-hidden flex flex-col-reverse lg:flex-row md:items-stretch ">
            <div className="relative h-[150px] w-full lg:w-[70%] flex md:h-[350px] md:min-w-[540px] clip-image-top sm:clip-image-bottom clip-image-right">
              <span
                style={{
                  boxSizing: "borderBox",
                  display: "block",
                  overflow: "hidden",
                  width: "initial",
                  height: "initial",
                  background: "none",
                  opacity: 1,
                  border: "0px",
                  margin: "0px",
                  padding: "0px",
                  position: "absolute",
                  inset: "0px",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    inset: "0px",
                    boxSizing: "bordeBox",
                    padding: "0px",
                    border: "none",
                    margin: "auto",
                    display: "block",
                    width: "0px",
                    height: "0px",
                    minWidth: "100%",
                    maxWidth: "100%",
                    minHeight: "100%",
                    maxHeight: " 100%",
                    objectFit: "cover",
                  }}
                  className=""
                  src="/assets/info2.jpg"
                  alt=""
                />
              </span>
            </div>
            <div className="p-4 md:p-8 flex flex-col justify-center  ">
              <p className="text-left text-[#1a69b4] text-xl md:text-4xl md:text-36 leading-2 sm:leading-10 font-semibold">
                Your Gateway to Essential Connections
              </p>
              <p className="  text-elnk-black-700 py-2 text-md md:text-lg md:pt-4 md:pb-6 prose-a:underline prose-a:text-elnk-blue-600">
                From lightning-fast internet to premium streaming services,
                robust business packages, and top-tier security solutions, we're
                dedicated to keeping you connected.
              </p>
            </div>
          </div>
          <Info />
          <div className="p-6 bg-white rounded-md overflow-hidden flex flex-col-reverse lg:flex-row md:items-stretch ">
            <div className="relative h-[150px] w-full lg:w-[70%] flex md:h-[350px] md:min-w-[540px] clip-image-top sm:clip-image-bottom clip-image-right">
              <span
                style={{
                  boxSizing: "borderBox",
                  display: "block",
                  overflow: "hidden",
                  width: "initial",
                  height: "initial",
                  background: "none",
                  opacity: 1,
                  border: "0px",
                  margin: "0px",
                  padding: "0px",
                  position: "absolute",
                  inset: "0px",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    inset: "0px",
                    boxSizing: "bordeBox",
                    padding: "0px",
                    border: "none",
                    margin: "auto",
                    display: "block",
                    width: "0px",
                    height: "0px",
                    minWidth: "100%",
                    maxWidth: "100%",
                    minHeight: "100%",
                    maxHeight: " 100%",
                    objectFit: "cover",
                  }}
                  className=""
                  src="/assets/exp-img1.webp"
                  alt=""
                />
              </span>
            </div>
            <div className="p-4 md:p-8 flex flex-col justify-center   ">
              <p className="text-left text-[#1a69b4] text-xl md:text-4xl md:text-36 leading-2 sm:leading-10 font-semibold">
                You deserve the perfect connection.
              </p>
              <p className="  text-elnk-black-700 py-2 text-md md:text-lg md:pt-4 md:pb-6 prose-a:underline prose-a:text-elnk-blue-600">
                We rely on the internet for everything—work, entertainment,
                education, staying in touch with family, and more. That's why
                you need high-speed internet to stay connected. We offer a range
                of internet services, including fiber, satellite, and wireless
                home internet, so you can find the perfect connection.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Experience;

import React from 'react'
import AnimatedItem from '../Component/AnimatedItem'

function Footer() {
  return (
    <div>
        <div className=' bg-[#1a69b4]'>
        <div className=' lg:p-16 p-5 container mx-auto'>
          <AnimatedItem>
            <div className='flex flex-col sm:flex-row justify-between bg-white lg:mx-[1rem]   lg:p-10 p-4 rounded-xl items-center'>
                <h1 className='text-md sm:text-3xl font-bold'>Internet Mobile | TV | Phone!</h1>
                <button className="btn   px-4 py-2 rounded-md ml-4 text-white shadow-md bg-[#f2ae1b] hover:bg-white hover:text-[#f2ae1b] hover:border-2  hover:border-[#f2ae1b] hover:scale-y-125">Click to Connect</button>
            </div>
            <p className='lg:mx-[1rem] text-justify sm:text-start mx-[2rem]   py-4 text-slate-100'>Internet + TV Select + Voice: Special offer for new residential customers. TV Select is $49.99/mo for the first year, then standard rates apply. Internet is $49.99/mo for the first year, with standard rates thereafter. Voice is $14.99/mo with a bundle. Taxes, fees, and surcharges apply; installation, equipment, and additional services are extra. Speeds vary by connection type; TV equipment may incur additional charges. Unlimited calling includes select regions. Services subject to change and availability; enter your address to check eligibility. ©2024 Charter Communications.</p>
            </AnimatedItem>
        </div>
        </div>
        <div className='bg-[#88c5ff] '>
        <div className='bg-[#88c5ff] flex flex-col gap-2 sm:gap-0 sm:flex-row justify-between p-10 mx-auto container  text-s lg:text-md'>
            <a className='font-semibold' href='#'>Cable TV WiFi Internet</a>
            <p>Copyright © 2024 CableTvWiFiInternet. All rights reserved.</p>
            <a href='#'>Privacy & Policy</a>
        </div>
        </div>
    </div>
  )
}

export default Footer
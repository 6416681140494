// src/ImageMarquee.js
import React from 'react';

const images = [
  '/assets/image1.jpg',
  '/assets/image2.png',
  '/assets/image3.png',
  '/assets/image4.jpg',
  '/assets/image5.jpg',
  // Add more image paths here
];

const ImageMarquee = () => {
  return (
    <div className="marquee bg-white">
      <div className="marquee-content m-8">
        {images.map((src, index) => (
          <img key={index} src={src} alt={`Image ${index + 1}`} className="marquee-image max-w-full" />
        ))}
        {/* Repeat the images for a continuous effect */}
        {images.map((src, index) => (
          <img key={`duplicate-${index}`} src={src} alt={`Image ${index + 1}`} className="marquee-image" />
        ))}
      </div>
    </div>
  );
};

export default ImageMarquee;

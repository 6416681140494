import React from 'react'
import AnimatedItem from '../Component/AnimatedItem'

function Services() {
  return (
    <div className='pt-10 container mx-auto'>
        <AnimatedItem>
        <h1 className='text-center lg:text-4xl text-2xl font-bold pb-6'>How To Choose The Best Services In My Area</h1>
        <div className='block lg:flex justify-between'>
            <div className='bg-white p-6 m-6 text-center w-[90%] lg:w-[33%] shadow-md rounded-xl '>
                <img src='/assets/s1.webp' className='w-[20%] mx-auto'/>
                <h1 className='text-2xl font-bold py-6 glass-shadow'>Cable Tv Channels</h1>
                <p>
                Start by choosing the TV channels you want and the types that attract you.</p>
            </div>
            <div className='bg-white p-6 m-6 text-center  w-[90%] lg:w-[33%] shadow-md rounded-xl'>
                <img src='/assets/s2.webp' className='w-[20%] mx-auto'/>
                <h1 className='text-2xl font-bold py-6'>Internet Service</h1>
                <p>Assess your internet needs and download capacity based on the number of people in your home.</p>
            </div>
            <div className='bg-white p-6 m-6 text-center  w-[90%] lg:w-[33%] shadow-md rounded-xl'>
                <img src='/assets/s3.webp' className='w-[20%] mx-auto'/>
                <h1 className='text-2xl font-bold py-6'>Phone Service</h1>
                <p>Bundle offers simplify your needs by covering phone service and more with one provider, plus you get discounts.</p>
            </div>
        </div></AnimatedItem>
    </div>
  )
}

export default Services
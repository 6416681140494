import React from "react";
import AnimatedItem from "../Component/AnimatedItem";

function Client() {
  return (
    <div className="bg-[#88c5ff]">
      <AnimatedItem>
    <div className="lg:flex block justify-between  container mx-auto">
      <div className="m-6  w-[90%] lg:w-[50%]">
        <img src="/assets/client2.svg" className="rounded-[250px] w-[70%] mx-auto" />
      </div>
      <div className="m-6  w-[90%] lg:w-[50%] mt-10 py-10">
        <h1 className="text-4xl font-bold pb-5">What clients say about us:</h1>
        <h2 className="font-bold pb-5">“Fast connection, great entertainment.”</h2>
        <p className="w-[100%] lg:w-[70%]">“I signed up for ’s services because it was one of the only options in my area for Internet and cable, but with more options than ever, I have no plans on leaving. TV️, Internet, and phone isn’t breaking my bank, and their customer service reps are always available whenever I have a question.”</p>
        <p className="font-bold pt-5">Franck Daniel</p>
      </div>
    </div>
    </AnimatedItem>
    </div>
  );
}

export default Client;

import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import AnimatedItem from "../Component/AnimatedItem";

function Faq() {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    { question: "Why should I choose for my internet plan?", answer: "Choosing our internet plan ensures you get fast, reliable, and secure internet service tailored to your needs. Our plans offer excellent value with competitive pricing, extensive coverage, and 24/7 customer support to keep you connected without interruptions." },
    { question: "What other perks do I get with Internet?", answer: "With our internet service, you receive additional perks such as free access to Wi-Fi hotspots, enhanced security features, parental controls, and a complimentary subscription to our premium content services. Enjoy seamless streaming, gaming, and browsing experiences with our value-added benefits." },
    { question: "What are some of TV's programming options?", answer: "Our TV service offers a diverse range of programming options including popular live channels, on-demand movies, TV shows, sports packages, and premium networks. You can also access exclusive content and enjoy high-definition and 4K viewing experiences." },
    { question: "Why should I choose for my phone plan?", answer: "Our phone plans are designed to provide crystal-clear call quality, unlimited nationwide calling, and affordable international rates. Enjoy additional features like voicemail, call forwarding, caller ID, and spam call protection, all with reliable network coverage." },
    { question: "What are some of Voice's more popular features?", answer: "Some of the popular features of our Voice service include high-definition voice quality, voicemail-to-email transcription, three-way calling, call blocking, and customizable caller ID. Stay connected with advanced calling features that enhance your communication experience." },
    { question: "What are some of Mobile's more popular features?", answer: "Our mobile service offers features such as unlimited data plans, 5G connectivity, mobile hotspot capabilities, visual voicemail, and international roaming options. Experience the freedom of staying connected on the go with our comprehensive mobile plans." },
    { question: "What if I want to combine these services?", answer: "Combining our services allows you to enjoy significant savings with our bundle packages. Choose from our customized bundles that include internet, TV, phone, and mobile services. Benefit from a single bill, unified support, and exclusive discounts tailored to your needs." },
    { question: "What's the most popular plan?", answer: "Our most popular plan is the Triple Play bundle, which includes high-speed internet, extensive TV programming, and reliable phone service. This plan offers the best value, providing a comprehensive entertainment and communication solution for your home." },
    { question: "What discounts does offer?", answer: "We offer various discounts including promotional rates for new customers, bundle savings, and seasonal deals. Additionally, seniors, students, and military personnel can benefit from special discounts. Check our website or contact our customer service for the latest offers." },
    { question: "How soon can I enjoy services?", answer: "You can start enjoying our services as soon as your installation is complete. We offer flexible installation appointments, and in many cases, same-day or next-day installation is available. Our professional technicians ensure a seamless setup process." },
    { question: "What if I'm in another contract for these services?", answer: "If you're currently under contract with another provider, we can assist with a contract buyout program to help you switch to our services without penalty. Our customer service team will guide you through the process to make the transition smooth and hassle-free." },
  ];
  return (
    <div>
      <div className="lg:flex block justify-center mt-10 container mx-auto">
      <div className="w-[80%] lg:w-[60%] m-10">
      {faqs.map((faq, index) => (
        <div key={index} className="bg-white rounded-md shadow-md cursor-pointer m-3">
          <div className="p-5 font-semibold flex justify-between items-center" onClick={() => toggleFAQ(index)}>
            <p>{faq.question}</p>
            <FontAwesomeIcon icon={openIndex === index ? faChevronUp : faChevronDown} />
          </div>
          {openIndex === index && (
            <div className="p-5">
              <p>{faq.answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
        <div className=" w-[80%] lg:w-[40%] m-10">
          <AnimatedItem>
            <h1 className="text-4xl font-bold py-5">FAQ</h1>
            <p>Do you have a question about ’s services? Are you tired of combing through all the information out there online? Here, you can find the answers to the most frequently asked questions from our loyal customers.</p>
            <img src="/assets/faq.jpg"/>
            </AnimatedItem>
        </div>
      </div>
    </div>
  );
}

export default Faq;
